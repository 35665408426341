import clsx from 'clsx';
import { useHover } from 'react-aria';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Box } from '@components/Box/Box';
import AddToCart from '@components/Button/AddToCart';
import Button from '@components/Button/Button';
import LoaderWrapper from '@components/Loader/LoaderWrapper';
import { useAppContext } from '@context/AppContext';
import { useSelectedProduct } from '@hooks/useSelectedProduct';
import apiOms from '@utils/apiOms';
import { DEFAULT_CURRENCYCODE, DEFAULT_IMG_PLACEHOLDER, DEFAULT_SITECODE } from '@utils/common';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '@utils/image';
import { cn } from '@utils/main';
import { ALERTS_KEY } from './config';
import type { WishlistAndAlertItem } from 'types/apiOms';

interface MutationProps {
    ean: string;
}

interface AlertListItemProps {
    className?: string;
    alert: WishlistAndAlertItem;
    index: number;
}

const AlertListItem = ({ className, alert, index }: AlertListItemProps) => {
    const { brandCode, site } = useAppContext();
    const { hoverProps } = useHover({});

    const queryClient = useQueryClient();

    const { mutate: deleteItemMutate, isLoading: isLoadingDeleteItem } = useMutation(
        ({ ean }: MutationProps) => apiOms.omsAuthenticated.deleteAlertAuthenticated(ean),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [ALERTS_KEY] });
            },
        }
    );
    const { mutate: replaceItemMutate, isLoading: isLoadingReplaceItem } = useMutation(
        (ean: string) =>
            apiOms.omsAuthenticated.replaceAlertAuthenticated(
                alert.ean!,
                {
                    brandCode,
                    siteCode: site.code || DEFAULT_SITECODE,
                    currencyCode: site?.defaultCurrency?.code || DEFAULT_CURRENCYCODE,
                    locale: site.defaultLocale?.code || DEFAULT_SITECODE,
                },
                { newEan: ean }
            ),
        {
            onSettled: () => {
                queryClient.invalidateQueries({ queryKey: [ALERTS_KEY] });
            },
        }
    );

    const { selectedProductLink } = useSelectedProduct(alert);
    const isAvailable = alert?.status?.label === 'available';

    const productImage = (
        <img
            loading={index < 2 ? 'eager' : 'lazy'}
            width={DEFAULT_IMAGE_WIDTH}
            height={DEFAULT_IMAGE_HEIGHT}
            src={alert?.imageUrl || DEFAULT_IMG_PLACEHOLDER}
            className={clsx('h-full w-full bg-gray-200 object-cover', !isAvailable && 'opacity-50')}
            alt={alert?.name ?? ''}
        />
    );

    return alert?.ean ? (
        <Box border={false} className={className} imageBox>
            <div {...hoverProps} className="group relative">
                {alert.link && selectedProductLink ? (
                    <a href={selectedProductLink} target="_blank" rel="noreferrer">
                        {productImage}
                    </a>
                ) : (
                    productImage
                )}
                <div
                    className={clsx(
                        isAvailable ? 'hover:bg-white/40' : 'hover:bg-black/40',
                        'absolute left-0 top-0 h-full w-full bg-transparent transition-all duration-500 ease-in-out group-hover:visible'
                    )}
                >
                    <div className="flex h-full w-full flex-col justify-center ">
                        <Button
                            floating
                            color="tertiary"
                            disabled={isLoadingDeleteItem}
                            onClick={() =>
                                alert.ean &&
                                deleteItemMutate(
                                    { ean: alert.ean },
                                    {
                                        onSettled: () => {
                                            queryClient.invalidateQueries([ALERTS_KEY]);
                                        },
                                    }
                                )
                            }
                            className="absolute right-0 top-0 px-5 text-3xl leading-8 !no-underline"
                        >
                            <LoaderWrapper isLoading={isLoadingDeleteItem} loaderProps={{ small: true }}>
                                ✕
                            </LoaderWrapper>
                        </Button>

                        <span className="flex justify-center mobile:hidden">
                            {isAvailable && <AddToCart className="w-auto" ean={alert.ean} />}
                        </span>
                        <span className="flex self-center mobile:hidden">
                            {!isAvailable && (
                                <span className="visible border border-white px-12 py-5 text-center font-account-heading font-button-font-weight uppercase text-white">
                                    <FormattedMessage id="alerts.unavailable" />
                                </span>
                            )}
                        </span>
                    </div>
                </div>
            </div>
            <h3 className="mb-2 mt-8 text-center font-account-subheading font-medium uppercase">
                {alert.link && selectedProductLink ? (
                    <a href={selectedProductLink} target="_blank" rel="noreferrer">
                        {alert?.name}
                    </a>
                ) : (
                    alert?.name
                )}
                {alert?.displayPrice && <span className="text-gray-medium"> — {alert?.displayPrice}</span>}
            </h3>
            <span className="mb-5 text-center font-account-heading text-sm text-gray-light desktop:text-sm">
                {alert?.sizes &&
                    alert.sizes.map(size => (
                        <Button
                            key={size.ean}
                            color="tertiary"
                            className={cn(size.size === alert.size ? 'text-black underline' : 'font-normal', 'p-1')}
                            disabled={isLoadingReplaceItem || size.size === alert?.size}
                            onClick={() => alert?.ean && size.ean && replaceItemMutate(size.ean)}
                        >
                            {size.available ? size.size : <s>{size.size}</s>}
                        </Button>
                    ))}
            </span>
            {isAvailable ? (
                <span className="mt-5 flex w-full self-center desktop:hidden">
                    <AddToCart ean={alert.ean} className="px-0! w-full self-center text-sm" />
                </span>
            ) : (
                <span className="mt-5 flex w-full self-center desktop:hidden">
                    <span className="visible grow border border-gray-lighter py-5 text-center font-account-heading text-sm font-medium uppercase text-black">
                        <FormattedMessage id="alerts.unavailable" />
                    </span>
                </span>
            )}
        </Box>
    ) : null;
};

export default AlertListItem;
